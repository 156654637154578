
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "../../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { DateTimeUtils } from "@/latipay/utils/DateTimeUtils";

  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { EnumsConstant } from "@/store/enums/constants";
  import { ref } from "vue";
  import { reactive } from "vue";
  import { getAccountStatementsRequest } from "@/latipay/apis/services/AccountStatementsService";
  import { getAccountsRequest } from "@/latipay/apis/services/AccountsService";


  export default defineComponent({
    name: "ledgerDetail",
    components: {},
    props: {
      id: Number
    },
    setup(props) {
      const store = useStore();

      const operationId = ref<string | null>(null);
      const accountFromId = ref<string | null>(null);
      const accountToId = ref<string | null>(null);

      const sort = ref<string | null>(null);
      const pageNumber = ref(1);
      const pageSize = ref(20);

      const loading = ref(false);
      const countries = EnumsConstant.CountryCode;


      const formInline = reactive({
        operationId: "",
        accountFromId: "",
        accountToId: ""
      });

      const {
        accountStatementsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getAccountStatements
      } = getAccountStatementsRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getAccountStatements(
          formInline.operationId,
          formInline.accountFromId,
          formInline.accountToId,


          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(accountStatementsRef);
      };

      const {
        accountsRef,

        getAccounts
      } = getAccountsRequest();
      const getAccountsData = async () => {
        loading.value = true;

        // await updateQueries();
        await getAccounts(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          1,
          10000
        );
        reinitializeComponents();

        loading.value = false;

        console.log(accountsRef);
      };


      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
        await getAccountsData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };

      return {
        getIllustrationsPath,
        formInline,
        countries,
        accountsRef,
        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        accountStatementsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        operationId,
        accountFromId,
        accountToId,
        DateTimeUtils
      };
    },
    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();

        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      }

    }
  });
